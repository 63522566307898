/** @jsx jsx */
import { useEffect, useState } from 'react'
import { jsx, Spinner } from 'theme-ui'
//

const GATSBY_LIKES_URL = process.env.GATSBY_LIKES_URL

const Heart = ({ filled = 0 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 15 15"
    viewBox="0 0 15 15"
    width="16px"
    preserveAspectRatio="none"
  >
    <path
      fill={filled ? '#C50000' : 'rgba(197, 0, 0, 0)'}
      stroke="#C50000"
      strokeWidth="1"
      d="M13.91 6.75c-1.17 2.25-4.3 5.31-6.07 6.94a.5.5 0 01-.67 0C5.39 12.06 2.26 9 1.09 6.75-1.48 1.8 5-1.5 7.5 3.45c2.5-4.95 8.98-1.65 6.41 3.3z"
      paintOrder="stroke"
    />
  </svg>
)

const Like = ({ id }) => {
  const [likes, setLikes] = useState(null)
  const [loading, setLoading] = useState(true)

  const likeIt = async () => {
    setLoading(true)
    const res = await fetch(`${GATSBY_LIKES_URL}?id=${id}`, { method: 'PUT' })
      .then(res => res.json())
      .catch(() => {})
    if (res?.count) {
      setLikes(res?.count)
    }
    setLoading(false)
  }

  useEffect(() => {
    async function fetchLikes() {
      const res = await fetch(`${GATSBY_LIKES_URL}?id=${id}`)
        .then(res => res.json())
        .catch(err => console.error(err))
      if (res?.count) {
        setLikes(res?.count)
      }
      setLoading(false)
    }

    fetchLikes()
  }, [id])

  if (loading) {
    return <Spinner size={20} variant="styles.spinner" />
  }

  return (
    <button
      onClick={likeIt}
      sx={{
        color: 'lightText',
        textDecoration: 'none',
        background: 'none',
        border: 'none',
        minWidth: '64px',
        width: 'auto',
        height: '18px',
        cursor: 'pointer',
        display: 'flex',
        ':hover': {
          '*': {
            color: 'text',
            fill: 'text',
            stroke: 'text'
          }
        }
      }}
    >
      <Heart filled={likes > 0} />
      <span sx={{ minWidth: '32px', marginLeft: '4px', textAlign: 'left' }}>{likes || 'Like'}</span>
    </button>
  )
}

export default Like
