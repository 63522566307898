/** @jsx jsx */
import { useEffect, useState } from 'react'
import { Styled, jsx, Grid, Box } from 'theme-ui'
import { graphql, navigate } from 'gatsby'
import Modal from 'react-modal'
import Iframe from 'react-iframe'
import InnerImageZoom from 'react-inner-image-zoom'
//
import Layout from '../components/layout'
import Seo from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import Like from '../components/like'

import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import { SocialShare } from '../components/share'

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    border: 'none',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: 'none',
    outline: 'none',
    padding: '0px'
  }
}

const PageTemplate = ({ data, location, pageContext }) => {
  const { shortUrl, description } = pageContext
  const { external_id: externalId, slug, name, thumbnail_url, variants } = data?.printfulProduct

  const [activeImage, setActiveImage] = useState({ id: 0, url: thumbnail_url })

  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalRoot, setModalRoot] = useState(null)

  const breadcrumbItems = [
    {
      label: 'Shop',
      slug: '/shop',
      action: () => {
        navigate(-1)
      }
    },
    { label: name, slug: '' }
  ]

  useEffect(() => {
    setModalRoot(document.getElementsByTagName('body'))
  }, [])

  const changeActiveImage = (e, image) => {
    e.preventDefault()
    if (activeImage.id === image.id) {
      setActiveImage({ id: 0, url: thumbnail_url })
    } else {
      setActiveImage(image)
    }
  }

  const getThumbVariant = files => {
    return files?.filter(file => file?.type === 'preview')?.[0]
  }

  return (
    <Layout location={location}>
      <Seo
        title={name}
        description={description.replace(/<[^>]*>?/gm, '').substr(0, 160)}
        image={thumbnail_url}
        cardType="summary"
      />

      <Breadcrumb items={breadcrumbItems} />

      <Styled.div sx={{ width: '100%', maxWidth: '1024px', marginTop: '1rem' }}>
        <Grid sx={{ display: 'grid' }} gap={2} columns={[1, '2.5fr 2fr']}>
          <Box sx={{ position: 'relative' }}>
            <InnerImageZoom
              key={activeImage?.url}
              src={activeImage?.url}
              alt={`${name} image mockup`}
              zoomScale={1.1}
            />
          </Box>

          <Box>
            <Styled.h2 sx={{ mb: 2, marginBlockStart: 0, fontWeight: 400 }}>{name}</Styled.h2>
            <Styled.div
              sx={{ fontSize: '.925rem' }}
              className="product-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <Styled.div
              sx={{ paddingLeft: '5px', marginTop: '1rem', fontSize: '.925rem' }}
              className="product-variants"
            >
              {variants?.map(variant => {
                const thumbVariant = getThumbVariant(variant?.files)
                const isActiveImage = variant.id === activeImage.id

                return (
                  <Styled.div
                    key={variant.id}
                    sx={{
                      borderTopStyle: 'solid',
                      borderTopWidth: '0.5px',
                      borderTopColor: 'ultraLigther',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '0.5px',
                      borderBottomColor: 'ultraLigther',
                      marginTop: '-1px',
                      padding: '0.375rem 0px'
                    }}
                  >
                    <Styled.div sx={{ display: 'flex' }}>
                      <Styled.div>
                        <button
                          sx={{
                            marginRight: '10px',
                            background: 'transparent',
                            color: 'inherit',
                            border: 'none',
                            padding: 0,
                            cursor: 'pointer',
                            outline: 'inherit'
                          }}
                          href="#"
                          aria-label="View product"
                          onClick={e => changeActiveImage(e, { id: variant.id, url: thumbVariant?.preview_url })}
                        >
                          <img
                            src={thumbVariant?.thumbnail_url}
                            alt={variant.catalogVariant.name}
                            sx={{
                              borderStyle: isActiveImage ? 'solid' : 'none',
                              borderWidth: isActiveImage ? '1px' : '0',
                              borderColor: isActiveImage ? 'text' : 'transparent',
                              height: ['64px', '72px', '92px'],
                              objectFit: 'cover',
                              '&:hover': {
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'text'
                              }
                            }}
                          />
                        </button>
                      </Styled.div>
                      <Styled.div>
                        <Styled.p style={{ fontSize: '0.925rem' }}>
                          {variant.catalogVariant.name
                            .replace(variant.catalogVariant.size, '')
                            .replace(variant.catalogVariant.color, '')
                            .replace(/(\(\/\)|\(\)|\( \/ [a-zA-Z]\)|\( \/ \))/, '')
                            .trim()}
                        </Styled.p>
                        <Styled.p style={{ fontSize: '0.825rem' }}>
                          Size: {variant.catalogVariant.size}
                          {variant?.catalogVariant?.color ? ` - ${variant.catalogVariant.color}` : null}
                        </Styled.p>
                        <Styled.p style={{ fontWeight: '600' }}>
                          {variant.retail_price} {variant.currency}
                        </Styled.p>
                      </Styled.div>
                    </Styled.div>
                  </Styled.div>
                )
              })}
            </Styled.div>
            <Styled.div sx={{ p: 2 }}>
              <small>VAT included</small>
            </Styled.div>
            <Styled.div sx={{ marginTop: '1rem', p: 1 }}>
              <Styled.div sx={{ display: 'flex' }}>
                <a
                  sx={{
                    padding: '4px 8px',
                    minWidth: '120px',
                    minHeight: '32px',
                    lineHeight: '28px',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'text',
                    textTransform: 'uppercase',
                    fontSize: '0.875rem',
                    backgroundColor: 'text',
                    textDecoration: 'none',
                    color: 'background',
                    ':hover': {
                      backgroundColor: 'background',
                      color: 'text'
                    }
                  }}
                  onClick={e => {
                    e.preventDefault()
                    setIsOpen(true)
                  }}
                  href={shortUrl}
                  rel="noreferrer"
                  target="_blank"
                >
                  <span>I Want This</span>
                </a>
                <Styled.div sx={{ marginLeft: '2px', alignSelf: 'center' }}>
                  <Like id={slug} />
                </Styled.div>
                <Styled.div sx={{ display: 'flex', width: '100%', alignSelf: 'center', justifyContent: 'end' }}>
                  <SocialShare url={location.href} image={thumbnail_url} />
                </Styled.div>
              </Styled.div>
              <Styled.div sx={{ fontSize: '.725rem', lineHeight: '.9', marginTop: '0.5rem' }}>
                <small>
                  Your purchase is made directly via gumroad.com.
                  <br />
                  No personal data is collected or cookies are used on my website.
                </small>
              </Styled.div>
            </Styled.div>
          </Box>
        </Grid>
        {modalRoot ? (
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            preventScroll={false}
            contentLabel="I Want this"
            appElement={modalRoot}
          >
            <button
              aria-label="Abort purchase and close modal"
              sx={{
                cursor: 'pointer',
                border: 'none',
                position: 'absolute',
                backgroundColor: 'text',
                color: 'background',
                zIndex: 999,
                left: 1,
                top: 1,
                padding: '4px 8px'
              }}
              onClick={() => setIsOpen(false)}
            >
              close ×
            </button>
            <Iframe
              url={shortUrl}
              position="absolute"
              width="100%"
              id={`product-${externalId}`}
              className="modal-iframe"
              height="100%"
              styles={{ border: 'none' }}
            />
          </Modal>
        ) : null}
      </Styled.div>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query ProductById($externalId: String!) {
    site {
      siteMetadata {
        title
      }
    }
    printfulProduct(external_id: { eq: $externalId }) {
      slug
      external_id
      thumbnail_url
      name
      id
      variants {
        id
        currency
        retail_price
        files {
          thumbnail_url
          preview_url
          visible
          type
        }
        catalogVariant {
          color
          name
          size
        }
      }
    }
  }
`
